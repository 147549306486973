import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dynamic_form = _resolveComponent("dynamic-form")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.showForm,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.showForm) = $event)),
        centered: "",
        size: "xl",
        title: _ctx.state.modalTitle
      }, {
        "btn-ok": _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "primary",
            disabled: _ctx.state.saving,
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')) + " ", 1),
              (_ctx.state.saving)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    small: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_dynamic_form, {
            modelValue: _ctx.state.itemEdit,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.itemEdit) = $event)),
            "form-definition": _ctx.formDefinition,
            "validation-result": _ctx.validationResult
          }, null, 8, ["modelValue", "form-definition", "validation-result"])
        ]),
        _: 1
      }, 8, ["modelValue", "title"]),
      _createVNode(_component_b_advanced_table, {
        "table-array": [_ctx.table],
        "sticky-header": "calc(100vh - 105px)",
        loading: _ctx.state.loading,
        "dynamic-columns": ""
      }, {
        toprow: _withCtx(() => [
          _createVNode(_component_b_button, {
            size: "sm",
            variant: "tertiary",
            disabled: !_ctx.Permissions.ADMINISTRATION.canEditCarriers(),
            "disabled-message": _ctx.getTranslation('core.common.youDoNotHavePermissionToAddCarriers'),
            onClick: _ctx.openAdd
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addCarrier')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "disabled-message", "onClick"])
        ]),
        action: _withCtx((row) => [
          _createVNode(_component_b_dropdown, {
            "no-caret": "",
            "close-on-click": ""
          }, {
            "button-content": _withCtx(() => [
              _createVNode(_component_faicon, { icon: "ellipsis-h" })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                disabled: !_ctx.Permissions.ADMINISTRATION.canEditCarriers(),
                onClick: () => _ctx.openEdit(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"]),
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                onClick: () => _ctx.openCarrierHistory(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.showHistory')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["table-array", "loading"])
    ]),
    _: 1
  }))
}