
import {
    defineComponent, reactive, onBeforeMount, computed,
} from 'vue';
import Carrier from '@/domain/Carrier';
import CarrierService from '@/modules/master-data/services/CarrierService';
import { FormDefinition, TableDefinition } from '@/types';
import DynamicForm from '@/components/form/DynamicForm.vue';
import Permissions from '@/services/permissions/Permissions';
import useValidator from '@/validation/useValidator';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import MasterDataRouteTypes from '../../routes/types';
import EntityType from '@/domain/enums/EntityTypes';
import router from '@/router';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

type State = {
    carriers: Array<Carrier>;
    loading: boolean;
    carriersTable: TableDefinition<Carrier> | null;
    itemEdit: Carrier;
    showForm: boolean;
    modalTitle: string;
    saving: boolean;
};

export default defineComponent({
    name: 'carrier-list',
    components: { BSpinner, DynamicForm },

    setup() {
        const carrierService = new CarrierService();

        const { validateForm, validationResult, clearResults } = useValidator<Carrier>('carrier');

        const state = reactive<State>({
            carriers: [],
            loading: true,
            carriersTable: null,
            itemEdit: new Carrier(),
            showForm: false,
            modalTitle: '',
            saving: false,
        });

        onBeforeMount(async () => {
            const response = await carrierService.getAllCarriers();
            if (response.success) {
                state.carriers = response.carriers;
                state.loading = false;
            }
        });

        const table = computed(
            (): TableDefinition<Carrier> => ({
                items: state.carriers,
                key: 'carrierList',
                name: getTitleCaseTranslation('core.domain.carriers'),
                columnDefinition: [
                    {
                        key: 'name',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.name'),
                    },
                    {
                        key: 'shortName',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.shortName'),
                    },
                    {
                        label: getTranslation('core.domain.scac'),
                        key: 'scac',
                        searchable: true,
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.dotRegistration'),
                        key: 'dotRegistration',
                        searchable: true,
                    },
                    {
                        label: getTitleCaseTranslation('core.common.contactName'),
                        key: 'contact',
                        searchable: true,
                    },
                    {
                        key: 'phone',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.phone'),
                    },
                    {
                        key: 'email',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.email'),
                    },
                ],
            }),
        );

        const formDefinition: Array<FormDefinition<Carrier>> = [
            {
                key: 'name',
                type: 'string',
                autofocus: true,
                label: getTitleCaseTranslation('core.domain.name'),
            },
            {
                key: 'shortName',
                type: 'string',
                label: getTitleCaseTranslation('core.domain.shortName'),
            },
            {
                label: getTranslation('core.domain.scac'),
                key: 'scac',
                type: 'string',
            },
            {
                label: getTitleCaseTranslation('core.domain.dotRegistration'),
                key: 'dotRegistration',
                type: 'string',
            },
            {
                label: getTitleCaseTranslation('core.common.contactName'),
                key: 'contact',
                type: 'string',
            },
            {
                key: 'phone',
                type: 'string',
                label: getTitleCaseTranslation('core.domain.phone'),
            },
            {
                key: 'email',
                type: 'string',
                label: getTitleCaseTranslation('core.domain.email'),
            },
        ];

        function openAdd() {
            clearResults();
            state.itemEdit = new Carrier();
            state.modalTitle = getTitleCaseTranslation('core.common.newCarrier');
            state.showForm = true;
        }

        function openEdit(carrier: Carrier) {
            clearResults();
            state.itemEdit = new Carrier(carrier);
            state.modalTitle = getTitleCaseTranslation('core.common.editCarrier');
            state.showForm = true;
        }

        async function openCarrierHistory(carrier: Carrier) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.CARRIER, entityId: carrier.id },
            });
        }

        async function save() {
            validateForm(state.itemEdit);

            if (validationResult.isValid) {
                state.saving = true;

                if (state.itemEdit.id > 0) {
                    const response = await carrierService.updateCarrier(state.itemEdit);
                    if (response) {
                        state.carriers = state.carriers.map((data) => {
                            if (data.id === state.itemEdit.id) {
                                return state.itemEdit;
                            }
                            return data;
                        });
                        state.showForm = false;
                    }
                } else {
                    const response = await carrierService.addNewCarrier(state.itemEdit);
                    if (response) {
                        state.carriers.unshift(response);
                        state.showForm = false;
                    }
                }

                state.saving = false;
            }
        }

        return {
            state,
            formDefinition,
            openAdd,
            openEdit,
            openCarrierHistory,
            save,
            table,
            Permissions,
            validationResult,
            getTitleCaseTranslation,
            getTranslation,
        };
    },
});
